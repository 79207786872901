import React from "react";
import styled from "styled-components";
import Page from "components/Page";
import { Link } from "components/Link";

interface Props {
  pageContext: {
    locale: string;
    title: string;
    description: string;
    list: {
      value: string;
      url: string;
    }[];
  };
}

export default function ListPage({ pageContext }: Props) {
  const list = pageContext.list;

  return (
    <Page
      title={pageContext.title}
      description={pageContext.description}
      localeCode="it"
      isPostPage={true}
      pageLocales={[
        {
          code: "it",
          url: "/news/tags"
        }
      ]}
    >
      <PageContainer>
        <Label>Tags:</Label>
        <ListTags>
          {list.map(el => (
            <Tag key={el.value}>
              <Link to={el.url}>{el.value}</Link>
            </Tag>
          ))}
        </ListTags>
      </PageContainer>
    </Page>
  );
}

const PageContainer = styled.div`
  padding-top: 250px;
  width: 700px;
  margin: 0 auto;
  padding-bottom: 100px;
  text-align: center;
  @media (max-width: 740px) {
    width: 100%;
    padding: 0 20px;
    padding-top: 200px;
    padding-bottom: 100px;
    box-sizing: border-box;
  }
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.title};
  padding-bottom: 15px;
  letter-spacing: 0.04em;
  line-height: 1.6em;
  color: #333;
  text-align: center;
  padding-bottom: 50px;
  @media (max-width: 750px) {
    font-size: 18px;
  }
`;

const ListTags = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Tag = styled.li`
  display: inline-block;
  font-weight: 700;
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.title};
  padding-bottom: 15px;
  letter-spacing: 0.04em;
  line-height: 1.6em;

  text-align: center;
  padding-left: 15px;
  :not(:last-child) {
    ::after {
      content: ", ";
      display: inline-block;
    }
  }
  @media (max-width: 750px) {
    font-size: 18px;
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.mainColor};
    transition: 0.3s all;

    &:hover {
      color: ${({ theme }) => theme.mainColorDark};
    }
  }
`;
